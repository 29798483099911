import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function FullStackSoftwareEngineer () {
  return (
    <CareerLayout title='Full-stack Software Engineer' location='Remote'>
      <h2>Overview</h2>
      <p className='mb'>As a full-stack engineer at Trexity, you’ll be part of a small team and will be exposed to many areas of our platform. You will work on areas such as partner integrations, our merchant-facing portal, and our mobile app for drivers. You will collaborate closely with the product and engineering teams, taking part in architectural and design discussions, scope planning, customer conversations, development and testing.</p>

      <h2>What you might do</h2>
      <ul className='trx-bullet mb'>
        <li>Scope, implement, and test partner integrations to help provide options for streamlining deliveries for our merchants</li>
        <li>Participate in architecture discussion, providing feedback and/or learning from the engineering team</li>
        <li>Implement features for our drivers in our driver app to help keep drivers happy and efficient</li>
        <li>Implement public and private REST APIs to help increase the capabilities of the platform</li>
        <li>Implement mapping and routing features to help visualize, and optimize driver routes</li>
        <li>Resolve support requests as needed.</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>You have at least 5 years of experience building and maintaining software professionally</li>
        <li>You either know or are able to demonstrate that you can master the following: JavaScript</li>
        <li>React and React Native</li>
        <li>Experience with database systems (e.g. Postgres, MySQL)</li>
        <li>Experience with web server technologies (e.g. Express, NodeJS)</li>
        <li>Experience with Git or similar SCM tools</li>
        <li>Experience with GCP, AWS, or similar</li>
        <li>Experience with CI / CD</li>
        <li>Experience with the command line and associated tools (e.g. Bash, Linux, MacOS)</li>
        <li>Experience working in a fast growth environment</li>
        <li>Bonus if you have experience with cloud technologies (e.g. GCP, AWS, Azure)</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
      </ul>
    </CareerLayout>
  )
}
